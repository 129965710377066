import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Row} from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";
import api from "../../middleware/api";
import moment from "moment";
import jwt_decode from "jwt-decode";

const CancelBooking = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();
    const [booking, setBooking] = useState({});
    const [searchParams] = useSearchParams();

    const cancelBooking = async () => {
        try {


            const requestSearchParams = new URLSearchParams({
                token: searchParams.get('token'),
            });


            await api.post(`booking/public/cancel?${requestSearchParams.toString()}`);

            setMessage({ type: 'success', text: 'Die Reservierung wurde erfolgreich storniert.' });
        } catch (e) {

            if(e.response.status === 406) {
                setMessage({type: 'error', text: 'Die Reservierung ist bereits storniert.'});
            } else {
                setMessage({type: 'error', text: 'Die Reservierung konnte nicht storniert werden. Bitte für die Stornierung telefonisch an uns wenden.'});
            }


        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        try {
            const token = searchParams.get('token');
            const bookingInfo = jwt_decode(token)?.booking;
            if(!bookingInfo) {
                throw new Error("token doesn't contain booking informations");
            }
            setBooking(bookingInfo);
        } catch(e) {
            setMessage({type: 'error', text: 'Der Link ist ungültig. Bitte für die Stornierung telefonisch an uns wenden.'});
        }

    }, [searchParams]);


    return (
        <Row>
            <Col span={24}>
                <div className={"login-content"}>
                    <h1 className={"login-H2"}>
                        Reservierung stornieren
                    </h1>
                    <p>Die Reservierung wirklich stornieren?</p>
                    {message && <Alert type={message.type} message={message.text} banner />}
                    <Row>
                        <Col span={24}>
                            <h3>Reservierungsinformationen</h3>

                            <Row>
                                <Col span={5}>
                                    <span>Personen:</span>
                                </Col>
                                <Col span={19}>
                                    <span><b>{booking?.persons}</b></span>
                                </Col>

                                <Col span={5}>
                                    <span>Datum:</span>
                                </Col>
                                <Col span={19}>
                                    <span><b>{moment(booking?.date, 'YYYY-MM-DD').format("DD.MM.YYYY")}</b></span>
                                </Col>

                                <Col span={5}>
                                    <span>Uhrzeiten:</span>
                                </Col>
                                <Col span={19}>
                                    <span><b>{(booking?.slots || []).map(slot => <><span>{moment(slot, 'YYYY-MM-DD HH:mm').format('HH:mm')} Uhr</span><br/></>)}</b></span>
                                </Col>
                            </Row>
                            <br/>
                        </Col>
                    </Row>
                    <div style={{textAlign: 'center', paddingTop: '15px'}}>

                    </div>
                    <div>
                        <br/>
                        <br/>
                        <Button
                            size={"large"}
                            block
                            onClick={cancelBooking}
                            type={"danger"}
                            htmlType={"button"}
                            disabled={!!message}
                        >Jetzt Stornieren</Button>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default CancelBooking;
