import React, {useEffect, useState} from 'react';
import {
    Alert,
    Col,
    Row,
    Steps,
    Statistic,
} from "antd";
import Book from "./book";
import BookContact from "./contact";
import BookPayment from "./payment";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {gtag_track_payment} from "../../helpers/gtag";

const { Step } = Steps;
const {Countdown} = Statistic;


const Booking = () => {

    const [currentStep, setCurrentStep] = useState(0);
    const [bookingValues, setBookingValues] = useState({});
    const [currentDate, setCurrentDate] = useState();

    const navigate = useNavigate();
    const location = useLocation();

    const setTimer = () => {
        setCurrentDate(Date.now() + 900000);
    };


    /*useEffect(() => {
        switch (currentStep) {
            case 0:
                navigate('/');
                break;
            case 1:
                navigate('/contact');
                break;
            case 2:
                navigate('/payment');
                break;
        }
    }, [currentStep]);*/

    const handleBack = (step) => {
        switch (step) {
            case 0:
                navigate('/cart');
                break;
            case 1:
                navigate('/contact');
                break;
            case 2:
                navigate('/payment');
                break;
        }
    };

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setCurrentStep(0);
                break;
            case '/contact':
                if (!bookingValues?.bookings) {
                    navigate('/');
                }
                setCurrentStep(1);
                break;
            case '/payment':
                if (!bookingValues?.bookings || (!bookingValues?.guest && !bookingValues?.customerId)) {
                    navigate('/');
                }
                setCurrentStep(2);
                break;
        }
    }, [location.pathname]);


    const trackPayment = async (cb) => {
        await gtag_track_payment({
            callback: cb,
            price: bookingValues?.prices?.summary || 1.0
        });
    }

    return (
        <Row>
            <Col span={24}>


                    <Row gutter={15}>

                        <Col span={24}>
                            <Steps size="small" current={currentStep} responsive={false}>
                                <Step title="Buchung" />
                                <Step title="Kontakt" />
                                <Step title="Bezahlung" />
                            </Steps>
                        </Col>

                        <Col span={24}>
                            <br/>
                            {currentStep > 0 ?
                                <Alert
                                    type={"info"}
                                    message={<span>
                                        Die ausgewählten Zeiten sind noch <Countdown style={{display: 'inline'}} valueStyle={{fontSize: '1em', display: 'inline', fontWeight: 'bold'}} value={currentDate} format={"mm:ss"}/> Minuten reserviert.
                                    </span>}
                                    showIcon={true} />
                            : null}
                            <br/>
                        </Col>

                        <Col span={24}>


                            <Routes>
                                <Route path="/*" element={<Book
                                    onSuccess={(values) => {
                                        window.scrollTo(0, 0);
                                        setBookingValues({...bookingValues, ...values});
                                        handleBack(1);
                                        setTimer();
                                    }}
                                    //initialValues={bookingValues ? {...bookingValues, date: moment(bookingValues.date, 'YYYY-MM-DD').toDate()} : {}}
                                    initialValues={bookingValues}
                                />}/>

                                <Route path="/contact" element={<BookContact
                                    onSuccess={(values) => {
                                        setBookingValues({...bookingValues, ...values});
                                        window.scrollTo(0, 0);
                                        handleBack(2);
                                    }}
                                    onBack={(step = 0) => {
                                        window.scrollTo(0, 0);
                                        handleBack(step);
                                    }}
                                    initialValues={bookingValues}
                                />}/>

                                <Route path="/payment" element={<BookPayment
                                    onSuccess={async () => {
                                        // track & navigate
                                        await trackPayment(() => navigate('/booking/success'));
                                    }}
                                    onBack={(step = 1) => {
                                        window.scrollTo(0, 0);
                                        handleBack(step);
                                    }}
                                    initialValues={bookingValues}
                                />}/>
                            </Routes>

                            {/*   {currentStep === 0 ?
                                <Book
                                    onSuccess={(values) => {
                                        window.scrollTo(0, 0);
                                        setBookingValues({...bookingValues, ...values});
                                        setCurrentStep(1);
                                        setTimer();
                                    }}
                                    initialValues={bookingValues ? {...bookingValues, date: moment(bookingValues.date, 'YYYY-MM-DD').toDate()} : {}}
                                />
                                : null
                            }

                            {currentStep === 1 ?
                                <BookContact
                                    onSuccess={(values) => {
                                        setBookingValues({...bookingValues, ...values});
                                        window.scrollTo(0, 0);
                                        setCurrentStep(2);
                                    }}
                                    onBack={(step = 0) => {
                                        window.scrollTo(0, 0);
                                        setCurrentStep(step);
                                    }}
                                    initialValues={bookingValues}
                                />
                                : null
                            }

                            {currentStep === 2 ?
                                <BookPayment
                                    onSuccess={(values) => {
                                        window.scrollTo(0, 0);
                                        setBookingValues({...bookingValues, ...values});
                                        setCurrentStep(2);
                                    }}
                                    onBack={(step = 1) => {
                                        window.scrollTo(0, 0);
                                        setCurrentStep(step);
                                    }}
                                    initialValues={bookingValues}
                                />
                                : null
                            }
                            */}
                        </Col>
                    </Row>

            </Col>
        </Row>
    );

}

export default Booking;
