import axios from 'axios';
import { message } from 'antd';

//export const API_URL = "http://localhost:8080/api/v1/";
export const API_URL = window.ENV.API_URL;




const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: `${API_URL}`,
    timeout: 5000,
});

// response interceptor for handling common errors (e.g. HTTP 500)
axiosInstance.interceptors.response.use(
    (response) => {return response},
    (error) => errorHandler(error)
);

axiosInstance.interceptors.request.use(
    async config => {
        config.headers = {
            'Authorization': "Bearer " + localStorage.getItem("access_token"),
            'MandatorId': window.ENV.MANDATOR,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });




const errorHandler = (error) => {
    if (!error?.response?.status) {
        message.error(`${error}`);
    }
    else {

        switch (error.response.status) {
            case 401:
                if(!error.config?.isAuth && !error.config?.isHello) {
                    window.location.href = "/login?logout=1&error=401";
                }
                break;
            case 403:
                message.error("Fehlende Berechtigungen für diese Aktion");
                break;
        }

    }

    return Promise.reject({ ...error })
}

export default axiosInstance;
