function getStartOfDay(date) {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
}

function getEndOfDay(date) {
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
}

function addDays(date, days) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
}

function subtractDays(date, days) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - days);
    return newDate;
}

export function convertToDisabledDates(events) {

    if(!events || events.length === 0) {
        return [];
    }

    const disabledDates = [];
    const sortedEvents = events.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

    let lastDisabledEnd = new Date('1970-01-01T00:00:00.000Z');

    sortedEvents.forEach((event) => {
        const eventDate = getStartOfDay(new Date(event.startDate));

        if (eventDate > lastDisabledEnd) {
            const disabledFrom = lastDisabledEnd;
            const disabledTo = subtractDays(eventDate, 1);

            if (disabledFrom <= disabledTo) {
                disabledDates.push({
                    from: new Date(disabledFrom),
                    to: new Date(disabledTo)
                });
            }
        }

        // Aktualisiere lastDisabledEnd auf den Tag nach dem Eventdatum
        lastDisabledEnd = addDays(eventDate, 1);
    });

    // Füge den letzten Bereich bis +10 Jahre hinzu
    const tenYearsLater = addDays(new Date(), 365 * 10);

    if (lastDisabledEnd <= tenYearsLater) {
        disabledDates.push({
            from: new Date(lastDisabledEnd),
            to: new Date(tenYearsLater)
        });
    }

    return disabledDates;
}