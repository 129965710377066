import React, {useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Row, Col, Form, Input, Alert} from "antd";
import "./auth.css";
import api from "../../middleware/api";
import {MailOutlined} from "@ant-design/icons";

const ForgotPassword = () => {
    let navigate = useNavigate();
    let location = useLocation();

    const [loading, setLoading] = useState(false);
    const [notify, setNotify] = useState({});
    const [sent, setSent] = useState(false);

    const [form] = Form.useForm();



    const requestPasswordReset = async (values) => {
        if (!values?.mail) {
            return;
        }
        try {
            setLoading(true);

            const searchParams = new URLSearchParams({
                email: values?.mail,
            });


            await api.get(`auth/public/password?${searchParams.toString()}`);
            setSent(true);
            setNotify({type: "success", message: "E-Mail wurde erfolgreich versendet."});
        } catch (e) { }
        finally {
            setLoading(false);
        }
    };



    return (
        <Row>
            <Col span={24}>
                <div className={"login-content"}>
                    <h1 className={"login-H2"}>
                        Passwort vergessen
                    </h1>
                    <p>Bitte hier die registrierte E-Mail Adresse eingeben, wenn das Passwort vergessen wurde. Wir senden eine E-Mail zum zurücksetzen des Passworts an diese Adresse.</p>
                    {notify && notify?.message && <Alert type={notify.type} message={notify.message} banner action={notify?.action} />}
                    {sent ?
                        <Row gutter={8}>
                            <Col span={24}>
                                <br/>
                                <Button size={"large"} block onClick={() => navigate("/login")} type={"primary"} htmlType={"button"}
                                        loading={loading}>Jetzt Anmelden</Button>
                            </Col>
                        </Row>
                        :
                        <Form
                            form={form}
                            onFinish={requestPasswordReset}
                            layout={"vertical"}
                            size={"large"}
                        >

                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item
                                        label={"E-Mail Adresse"}
                                        name={'mail'}
                                        required={true}
                                        rules={[{type: 'email', required: true}]}
                                    >
                                        <Input
                                            prefix={<MailOutlined style={{color: 'rgba(255,255,255,.25)'}}/>}
                                            placeholder="E-Mail Adresse"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <br/>
                                    <Button size={"large"} block disabled={loading} type={"primary"} htmlType={"submit"}
                                            loading={loading}>Passwort zurücksetzen</Button>
                                </Col>
                            </Row>
                        </Form>
                    }
                </div>
            </Col>
        </Row>
    );
}

export default ForgotPassword;
