import React, {useEffect, useState} from 'react';
import {
    Alert, Button,
    Col, Empty, Form, message,
    Row, Space, Spin,
} from "antd";
import api from "../../middleware/api";
import MyBooking from "../../components/MyBooking";
import {UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import BookingList from "./bookingList";

const Bookings = () => {

    const [error, setError] = useState(false);




    const PlaceholderActive = () => {

        return (
            <div style={{textAlign: 'center', padding: '15px 0px'}}>
                <h3>Keine bevorstehenden Reservierungen</h3>
                <Link to={"/"}><Button htmlType={"button"}>Jetzt reservieren</Button></Link>
            </div>
        );
    };

    const PlaceholderOutdated = () => {

        return (
            <div style={{textAlign: 'center', padding: '15px 0px'}}>
                <h3>Keine vergangenen Reservierungen</h3>
            </div>
        );
    };


    return (
        <Row>
            <Col span={24}>
                    <Row gutter={15}>

                        <Col span={24}>
                            <h1 className={"login-H2"}>
                                Reservierungen
                            </h1>
                            <p>Hier können Reservierungen eingesehen und verwaltet werden.</p>
                        </Col>

                        <Col span={24}>
                            {error && <Alert type={"error"} message={error} banner />}
                        </Col>
                        <Col span={24}>
                            <br/>
                        </Col>

                        <Col span={24}>
                            <h2>Aktuelle Reservierungen</h2>
                        </Col>

                        <Col span={24}>
                            <BookingList
                                pageSize={99}
                                Placeholder={PlaceholderActive}
                                outdated={false}
                                setActionError={setError}
                            />
                        </Col>

                        <Col span={24}>
                            <br/>
                        </Col>

                        <Col span={24}>
                            <h2>Vergangene Reservierungen</h2>
                        </Col>

                        <Col span={24}>
                            <BookingList
                                pageSize={5}
                                Placeholder={PlaceholderOutdated}
                                outdated={true}
                                setActionError={setError}
                            />
                        </Col>

                            {/*<Spin spinning={dataLoading}>
                                <Space direction="vertical" style={{width: '100%'}}>
                                    {data.map(booking => <MyBooking booking={booking} /> )}

                                    {data.length < count ?
                                        <Button htmlType={"button"} loading={dataLoading} onClick={() => fetchData(page + 1)}>ältere anzeigen</Button>
                                    : null}

                                    {data.length === 0 && !dataLoading ?
                                        <div style={{textAlign: 'center', padding: '15px 0px'}}>
                                            <h2>Du hast noch keine Buchungen</h2>
                                            <Link to={"/"}><Button htmlType={"button"}>Jetzt buchen</Button></Link>
                                        </div>
                                    : null
                                    }
                                </Space>
                            </Spin>*/}
                    </Row>

            </Col>
        </Row>
    );

}

export default Bookings;
