import React, {useEffect, useState} from 'react';
import api from "../../middleware/api";
import jwt_decode from "jwt-decode";

let AuthContext = React.createContext(null);

function AuthProvider({ children }) {
    const [JWT, setJWT] = useState(localStorage.getItem('access_token'));
    const [userInfo, setUserInfo] = useState({});
    const [authLoading, setAuthLoading] = useState(false);

    // send hello request on page load (if user is logged in) to verify session is still active
    useEffect(() => {
        if (JWT) {
            api.get(`auth/public/login/hello`, {isHello: true}).then(x => {}).catch(error => {
                if(error.response.status === 401) {
                    signout();
                }
            });
        }
    },[]);

    let signin = async ({username, password}) => {
        try {
            setAuthLoading(true);
            const response = await api.post(`auth/public/login`, {
                username,
                password,
            }, {isAuth: true});
            if(response.data.access_token) {
                setJWT(response.data.access_token);
                localStorage.setItem("access_token", response.data.access_token);
                const user = jwt_decode(response.data.access_token);
                return { error: false, JWT: response.data.access_token, userInfo: user };
            }
        } catch (e) {
            console.error(e);
            return { error: true, code: e.response.status, data: e.response.data };
        } finally {
            setAuthLoading(false);
        }
    };

    let registerCustomer = async (values) => {

        try {
            setAuthLoading(true);
            const response = await api.put(`auth/public/register`, values);
            if(response.data) {
                return { error: false };
            }
        } catch (e) {
            console.error(e);
            return { error: true, code: e.response.status, data: e.response.data  };
        } finally {
            setAuthLoading(false);
        }


    };

    let signout = async () => {
        setJWT(null);
        setUserInfo({});
        localStorage.removeItem("access_token");
    };

    useEffect(() => {
        if(JWT) {
            setUserInfo(jwt_decode(JWT));
        } else {
            setUserInfo({});
        }
    }, [JWT]);


    let value = { JWT, authLoading, userInfo, signin, registerCustomer, signout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}

export default AuthProvider;
