import React from 'react';
import { Button, Col, Row} from "antd";
import {MailOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";

const RegisterSuccess = () => {

    const navigate = useNavigate();

    return (
        <Row>
            <Col span={24}>
                <div className={"login-content"}>
                    <h1 className={"login-H2"}>
                        Registrieren
                    </h1>
                    <p>Bitte E-Mail Adresse bestätigen um die Anmeldung abzuschließen. Hierzu einfach auf den Link in der Registrierungsmail klicken.</p>
                    <div style={{textAlign: 'center', paddingTop: '15px'}}>
                        <MailOutlined style={{fontSize: '5rem'}}/>
                        <br/>
                        <br/>
                        <span style={{fontSize: '1.3rem'}}>Bitte E-Mail Adresse bestätigen</span>
                    </div>
                    <div>
                        <br/>
                        <br/>
                        <Button
                            size={"large"}
                            block
                            onClick={() => navigate("/login")}
                            type={"primary"}
                            htmlType={"button"}
                        >Jetzt Anmelden</Button>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default RegisterSuccess;
