import React, {useEffect, useState} from 'react';
import api from "../../middleware/api";

let AppContext = React.createContext(null);

function AppProvider({ children }) {

    const [mandatorInfo, setMandatorInfo] = useState(JSON.parse(localStorage.getItem("mandatorInfos") || "{}"));

    useEffect(() => {
        //window.document.title = window.ENV.TEST;
        loadMandatorInfos();
    }, []);

    useEffect(() => {
        window.document.title = `${mandatorInfo?.name || ""} - Online reservieren`;
    }, [mandatorInfo]);

    const loadMandatorInfos = async () => {
        try {
            //const response = await api.get(`mandator/${mandatorId}/public`);
            const response = await api.get(`mandator/${window.ENV.MANDATOR}/public`);

            if(response.data) {
                const _mandatorInfos = response.data;
                setMandatorInfo(_mandatorInfos);
                localStorage.setItem("mandatorInfos", JSON.stringify(_mandatorInfos));

            }
        } catch(e) {
            console.error(e);
        }
    };

    let value = { mandatorId: window.ENV.MANDATOR || "", mandatorInfo };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useApp() {
    return React.useContext(AppContext);
}

export default AppProvider;
