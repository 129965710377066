import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Input, message, Row, Space} from "antd";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import {useAuth} from "../containers/auth/AuthProvider";
import api from "../middleware/api";
import {Link} from "react-router-dom";

const LoginForm = ({ onSuccess, BackButton }) => {

    const [notify, setNotify] = useState({});
    let {signin, authLoading} = useAuth();
    const [form] = Form.useForm();

    const resendConfirmEmail = async (userId) => {
        if (!userId) {
            return;
        }
        try {
            await api.get(`auth/public/confirm/${userId}`);
            setNotify({type: "success", message: "E-Mail wurde erfolgreich versendet."});
        } catch (e) { }
    };

    const handleSubmit = async (values) => {

        let username = values.username || "";
        let password = values.password || "";

        const loginResponse = await signin({username, password});

        if(!loginResponse.error) {
            message.success("Erfolgreich angemeldet");
            onSuccess && onSuccess(loginResponse.userInfo);
        } else {
            window.scrollTo(0,0);
            switch(loginResponse.code) {
                case 428:
                    setNotify({
                            type: "error",
                            message: "Bitte E-Mail Adresse bestätigen um die Anmeldung abzuschließen. Hierzu einfach auf den Link in der Registrierungsmail klicken.",
                            action: <Space>
                                        <Button size="small" type="ghost" onClick={() => resendConfirmEmail(loginResponse.data.customerId)}>
                                            Erneut senden
                                        </Button>
                                    </Space>
                        });
                    break;
                case 401:
                    setNotify({
                        type: "error",
                        message: "Ihre E-Mail Adresse oder Ihr Passwort ist nicht korrekt." });
                    break;
                default:
                    setNotify({
                        type: "error",
                        message: "Es ist ein unbekannter Fehler aufgetreten. Bitte später erneut versuchen." });
                    break;
            }
        }
    };

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout={"vertical"}
            size={"large"}
        >
            {notify && notify?.message && <Alert type={notify.type} message={notify.message} banner action={notify?.action} />}
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        label={"E-Mail Adresse"}
                        name={'username'}
                        required={true}
                        rules={[ {type: 'email', required: true}]}
                    >
                        <Input
                            prefix={<MailOutlined style={{ marginRight: '10px' }} />}
                            placeholder="E-Mail Adresse"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={"Passwort"}
                        name={'password'}
                        required={true}
                        rules={[{required: true}]}
                    >
                        <Input.Password
                            prefix={<LockOutlined style={{ marginRight: '10px' }} />}
                            type="password"
                            placeholder="Passwort"
                            size="large"
                        />
                    </Form.Item>
                    <Link to={"/password/forgot"}>Passwort vergessen?</Link>
                </Col>
                <Col span={24}>
                    <br/>
                </Col>

                {BackButton ?
                    <>
                        <Col span={12}>
                            <BackButton />
                        </Col>
                        <Col span={12}>
                            <Button
                                size={"large"}
                                block
                                disabled={authLoading}
                                type={"primary"}
                                htmlType={"submit"}
                                loading={authLoading}>Anmelden</Button>
                        </Col>
                    </>
                    :

                    <Col span={24}>
                        <Button
                            size={"large"}
                            block
                            disabled={authLoading}
                            type={"primary"}
                            htmlType={"submit"}
                            loading={authLoading}>Anmelden</Button>
                    </Col>
                }
            </Row>
        </Form>
    )
}

export default LoginForm;
