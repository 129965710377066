import React, {useState} from 'react';
import RightMenu from './RightMenu'
import { Drawer, Button } from 'antd';
import {API_URL} from "../../../middleware/api";
import {useApp} from "../AppProvider";

const Navbar = () => {

	const [visible, setVisible] = useState();

	const {mandatorId} = useApp();

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	return (
		<nav className="menuBar">
			<div className="logo">
				<img alt={"logo"} src={`${API_URL}mandator/${mandatorId}/logo.png`} height={"auto"} width={"100%"}/>
			</div>
			<div className="menuCon">
				<div className="rightMenu">
					<RightMenu />
				</div>
				<Button className="barsMenu" type="primary" onClick={showDrawer}>
					<span className="barsBtn"></span>
				</Button>
				<Drawer
					title="Menü"
					placement="right"
					closable={true}
					onClose={onClose}
					visible={visible}
				>
					<RightMenu onSelect={onClose} />
				</Drawer>

			</div>
		</nav>
	);
}

export default Navbar;
