import React from 'react';
import { Button, Col, Row} from "antd";
import {CheckCircleOutlined, MailOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../auth/AuthProvider";

const BookingSuccess = () => {

    const navigate = useNavigate();
    const {JWT} = useAuth();

    return (
        <Row>
            <Col span={24}>
                <div className={"login-content"}>
                    <h1 className={"login-H2"}>
                        Erfolgreich reserviert!
                    </h1>
                    <p>Eine Reservierungsbestätigung wird in wenigen Minuten per E-Mail versandt. Dort sind auch nochmals die Buchungsdaten vermerkt.</p>
                    <div style={{textAlign: 'center', paddingTop: '15px'}}>
                        <CheckCircleOutlined style={{color: 'var(--global-main-color)', fontSize: '5rem'}}/>
                        <br/>
                        <br/>
                        <span style={{fontSize: '1.3rem'}}>Erfolgreich reserviert!</span>
                    </div>
                    <div style={{textAlign: 'center', paddingTop: '15px'}}>
                        <br/>
                        <br/>
                        <p>Diese Seite kann jetzt geschlossen werden.</p>
                    </div>
                    {JWT &&
                    <div>
                        <br/>
                        <br/>
                        <Button
                            size={"large"}
                            block
                            onClick={() => navigate("/profile")}
                            type={"primary"}
                            htmlType={"button"}
                        >Reservierungen verwalten</Button>
                    </div>}
                </div>
            </Col>
        </Row>
    )
}

export default BookingSuccess;
