import React from 'react';
import {Route, Routes} from "react-router-dom";
import RequireAuth from "../auth/RequireAuth";
import Settings from "./settings";
import Bookings from "./bookings";

const Profile = () => {


    return (
        <RequireAuth>
            <Routes>
                <Route path="/" element={<Bookings/>}/>
                <Route path="/settings" element={<Settings/>}/>
            </Routes>
        </RequireAuth>
    );

}

export default Profile;
