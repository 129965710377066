import React, {useEffect, useState} from 'react';
import {Col, Row} from "antd";
import ProductDetail from "./ProductDetail";

const ProductSelector = ({ value, onChange, products, availableProducts, hiddenProducts }) => {


    const [selection, setSelection] = useState(value);

    useEffect(() => {
        setSelection(value);

        // maxbe?
        //onChange && onChange(value);
    }, [value]);


    const select = (productId) => {

        setSelection(productId);
        onChange && onChange(productId);

    };


    return (
        <Row gutter={[8, 16]}>

            {products.map((product) => {
                const selected = selection === product.id;

                if(hiddenProducts.includes(product.id)) {
                    return;
                }

                return (
                    <Col xs={24} sm={12} key={product.id}>
                        <ProductDetail
                            key={product.id}
                            product={product}
                            selected={selected}
                            onChange={() => select(product.id)}
                            disabled={!(availableProducts || []).includes(product.id)}
                        />
                    </Col>
                );
            })}
        </Row>
    )
}

export default ProductSelector;
