import React, {useState} from 'react';
import {Alert, Button, Checkbox, Col, DatePicker, Divider, Form, Input, message, Row, Select} from "antd";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import {useAuth} from "../containers/auth/AuthProvider";
import {useApp} from "../containers/app/AppProvider";

const RegisterForm = ({ onSuccess, BackButton }) => {
    const {registerCustomer, authLoading} = useAuth();
    const [error, setError] = useState(false);
    const [form] = Form.useForm();

    const {mandatorInfo} = useApp();

    const handleSubmit = async (values) => {
        setError(false);
        values = {
            ...values,
            birthdate: values.birthdate ? values.birthdate.local().format("YYYY-MM-DD") : undefined,
        };

        const registerResponse = await registerCustomer(values);
        if(!registerResponse.error) {
            message.success("Erfolgreich registriert!");
            onSuccess();
        } else {
            window.scrollTo(0,0);
            switch(registerResponse.code) {
                case 409:
                    setError("Diese E-Mail Adresse ist bereits registriert. Bitte melde dich an.");
                    break;
                default:
                    setError("Es ist ein unbekannter Fehler aufgetreten. Bitte später erneut versuchen.");
                    break;
            }
        }
    };



    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout={"vertical"}
            size={"large"}
        >
            {error && <Alert type={"error"} message={error} banner />}
            <Row gutter={16}>
                <Col span={24}>
                    <Divider orientation="left">Kontaktdaten</Divider>
                </Col>

                <Col xs={24} md={12}>
                    <Form.Item
                        label={"Anrede"}
                        name={"title"}
                        required={true}
                        rules={[{required: true}]}
                    >
                        <Select placeholder={"Anrede"}>
                            <Select.Option value={"mr"}>Herr</Select.Option>
                            <Select.Option value={"mrs"}>Frau</Select.Option>
                            <Select.Option value={"company"}>Firma</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Form.Item shouldUpdate noStyle>
                    {() => form.getFieldsValue(['title'])?.title === "company" ?
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={"Firma"}
                                name={"company"}
                                required={true}
                                rules={[{required: true}]}
                            >
                                <Input placeholder={"Firmenname"} />
                            </Form.Item>
                        </Col>
                        : null }
                </Form.Item>

                 <Col xs={24} md={12}>
                    <Form.Item
                        label={"Vorname"}
                        name={"firstName"}
                        required={true}
                        rules={[{required: true}]}
                    >
                        <Input placeholder={"Vorname"} />
                    </Form.Item>
                </Col>
                 <Col xs={24} md={12}>
                    <Form.Item
                        label={"Nachname"}
                        name={"lastName"}
                        required={true}
                        rules={[{required: true}]}
                    >
                        <Input placeholder={"Nachname"} />
                    </Form.Item>
                </Col>

                 <Col xs={24} md={12}>
                    <Form.Item
                        label={"Straße, Hausnummer"}
                        name={"address"}
                        required={true}
                        rules={[{required: true}]}
                    >
                        <Input placeholder={"Straße, Hausnummer"} />
                    </Form.Item>
                </Col>
                 <Col xs={24} md={12}>
                    <Form.Item
                        label={"PLZ"}
                        name={"zipCode"}
                        required={true}
                        rules={[{required: true, min: 5, max: 5}]}
                    >
                        <Input placeholder={"Postleitzahl"} />
                    </Form.Item>
                </Col>
                 <Col xs={24} md={12}>
                    <Form.Item
                        label={"Ort"}
                        name={"city"}
                        required={true}
                        rules={[{required: true}]}
                    >
                        <Input placeholder={"Ort"} />
                    </Form.Item>
                </Col>
                 <Col xs={24} md={12}>
                    <Form.Item
                        label={"Geburtsdatum"}
                        name={"birthdate"}
                    >
                        <DatePicker style={{width: '100%'}} format={"DD.MM.YYYY"} />
                    </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                    <Form.Item
                        label={"Telefon"}
                        name={"phone"}
                        required={true}
                        rules={[{required: true}]}
                    >
                        <Input placeholder={"Telefonnummer"} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Divider orientation="left">Zugangsdaten</Divider>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={"E-Mail Adresse"}
                        name={'mail'}
                        required={true}
                        rules={[{required: true}]}
                    >
                        <Input
                            prefix={<MailOutlined style={{ marginRight: '10px' }} />}
                            placeholder="E-Mail Adresse"
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={"Passwort"}
                        name={'password'}
                        required={true}
                        rules={[{required: true, min: 8}]}
                    >
                        <Input.Password
                            prefix={<LockOutlined style={{ marginRight: '10px' }} />}
                            type="password"
                            placeholder="Passwort"
                            size="large"
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={"Zustimmung"}
                        name={"dsr"}
                        valuePropName={"checked"}
                        required={true}
                        initialValue={false}
                        rules={[{required: true, transform: value => (value || undefined), type: 'boolean', message: "Bitte stimme unseren Bestimmungen zu."}]}
                    >
                        <Checkbox>Ich willige ein, dass meine Daten zum Zwecke der Reservierung unter Berücksichtigung des Datenschutzgesetzes gespeichert werden. Meine Daten werden ausschließlich zu diesem Zweck genutzt. Dies kann ich jederzeit schriftlich widerrufen. Ausführliche Informationen stehen in unserer <a target="_blank" style={{color: 'inherit', textDecoration: 'underline'}} href={`${mandatorInfo?.privacyUrl}`}>Datenschutzerklärung</a>.</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={""}
                        name={"newsletter"}
                        valuePropName={"checked"}
                    >
                        <Checkbox>Ich willige ein, dass mich {mandatorInfo.company} per E-Mail über Informationen und Angebote informiert. Meine Daten werden ausschließlich zu diesem Zweck genutzt. Ich kann die Einwilligung jederzeit per E-Mail an {mandatorInfo.mail} oder durch Nutzung des in den E-Mails enthaltenen Abmeldelinks widerrufen. Ausführliche Informationen zum Newsletterversand erhalten Sie in unserer <a target="_blank" style={{color: 'inherit', textDecoration: 'underline'}} href={`${mandatorInfo?.privacyUrl}`}>Datenschutzerklärung</a>.</Checkbox>
                    </Form.Item>
                </Col>


                {BackButton ?
                    <>
                        <Col span={12}>
                            <BackButton />
                        </Col>
                        <Col span={12}>
                            <Button size={"large"} block disabled={authLoading} type={"primary"} htmlType={"submit"} loading={authLoading}>Registrieren</Button>
                        </Col>
                    </>
                    :

                    <Col span={24}>
                        <Button size={"large"} block disabled={authLoading} type={"primary"} htmlType={"submit"} loading={authLoading}>Registrieren</Button>
                    </Col>
                }


            </Row>
        </Form>
    )
}

export default RegisterForm;
