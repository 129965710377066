import React, {useEffect, useState} from 'react';
import {Navigate, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useAuth} from "./AuthProvider";
import {Button, Row, Col, Form, Input, Modal, message, Alert} from "antd";
import "./auth.css";
import LoginForm from "../../components/LoginForm";

const Login = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let {signout, JWT, authLoading} = useAuth();
    const [error, setError] = useState(false);

    let from = location.state?.from?.pathname || "/profile";
    const [searchParams] = useSearchParams();


    useEffect(() => {
        if(searchParams.get("logout")) {
            const actParams = searchParams;
            actParams.delete('logout');
            signout();
            navigate(`/login?${searchParams.toString()}`);
        }

        switch(searchParams.get("error")) {
            case "401":
                setError("Die Sitzung ist abgelaufen. Bitte erneut anmelden.");
                break;
            default:
                setError(undefined);

        }
    }, [searchParams]);

    useEffect(() => {
        if (JWT && !searchParams.get("logout")) {
            navigate(from);
        }
    }, [JWT, searchParams]);

    return (
        <Row>
            <Col span={24}>
                <div className={"login-content"}>
                    <h1 className={"login-H2"}>
                        Anmelden
                    </h1>
                    <p>Hier mit vorhandenen Konto Anmelden. Noch kein Konto? <a href={"#"} onClick={() => navigate("/register")}>Jetzt Registrieren</a></p>
                    {error && <Alert type={"error"} message={error} banner />}
                    <LoginForm />
                </div>
            </Col>
        </Row>
    );
}

export default Login;
