import React, {useState} from 'react';
import {
    Button,
    Col,
    Row,
    Form,
    Modal,
    Input, Select, Space, Alert
} from "antd";
import LoginForm from "../../components/LoginForm";
import {useAuth} from "../auth/AuthProvider";
import styled from "styled-components/macro";
import {UserOutlined, LoginOutlined, MailOutlined} from "@ant-design/icons";
import RegisterForm from "../../components/RegisterForm";
import RegisterSuccess from "../auth/registerSuccess";


const SelectionWrapper = styled.div`
          border: 1px solid #9e9e9e;
          border-radius: 5px;
          padding: 10px 25px;
          width: 100%;
          cursor: pointer;
          
          &:hover {
            border-color: var(--global-main-color);
            color: var(--global-main-color);
            
            /*
             border-color: var(--global-main-color);
            background-color: var(--global-main-color);
            color: #FFF;
            */
          }
          
          .productTitle {
            font-size: 1.2rem;
            display: block;
          }
          
          .actionIcon {
            vertical-align: bottom;
            height: 100%;
            font-size: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `;

const BookContact = ({onSuccess, onBack, initialValues}) => {

    let {JWT, userInfo} = useAuth();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);
    const [selection, setSelection] = useState(initialValues.guest ? "guest" : undefined);
    const [register, setRegister] = useState(false);
    const [registerSuccess, setRegisterSuccess] = useState(false);


    const [form] = Form.useForm();

    const handleBack = () => {
        if(!selection) {
            onBack();
        } else {
            setSelection(undefined);
        }
    };


   /* useEffect(() => {
        if(JWT && userInfo.sub) {
            onSuccess({customerId: userInfo.sub});
        }
    }, [JWT, userInfo]);*/


    const submitCustomer = () => {
        if(JWT && userInfo.sub) {
            onSuccess({customerId: userInfo.sub});
        }
    };

    const handleSubmit = async (values) => {
        try {
            setSaving(true);
            onSuccess(values);

        } catch(e) {
            window.scrollTo(0,0);
            switch(e.response.status) {
                case 410:
                    setError("Die Reservierungszeiten sind leider nicht mehr verfügbar. Bitte eine andere Zeit auswählen.");
                    break;
                default:
                    setError("Es ist ein unbekannter Fehler aufgetreten. Bitte später erneut versuchen.");
                    break;
            }
            console.error(e);
        } finally {
            setSaving(false);
        }
    };




    const SelectionBox = ({Icon, title, description, onClick}) => {

        return (
            <SelectionWrapper onClick={onClick}>
                <Row>
                    <Col span={4}>
                        <Icon className={"actionIcon"} />
                    </Col>
                    <Col span={20}>
                        <span className={'productTitle'}>{title}</span>
                        <span>{description}</span>
                    </Col>
                </Row>
            </SelectionWrapper>
        );
    };

    return (
        <>
            {error && <Alert type={"error"} message={error} banner />}
            <Row gutter={15}>

                <Col span={24}>
                    <h1 className={"login-H2"}>
                        Kontaktdaten
                    </h1>
                    {!register ? <p>Bitte anmelden, wenn bereits ein Benutzerkonto besteht. Die Kontaktdaten werden anschließend aus dem Konto verwendet. Alternativ:  <a href={"#"} onClick={() => setSelection("guest")}>als Gast fortfahren</a>.</p> : null}
                </Col>


                {!selection ?
                    <Col span={24}>
                        <Space direction={"vertical"} style={{width: '100%'}}>
                            {JWT ?
                                <SelectionBox
                                    Icon={LoginOutlined}
                                    title={<span>Als <b>{userInfo.firstName} {userInfo.lastName}</b> fortfahren</span>}
                                    description={"Mit aktuellem Konto fortfahren. Die Reservierung kann anschließend im Konto verwaltet werden."}
                                    onClick={submitCustomer}
                                />
                            :

                                <SelectionBox
                                    Icon={LoginOutlined}
                                    title={"Anmelden"}
                                    description={"Die Reservierung kann anschließend im Konto verwaltet werden."}
                                    onClick={() => setSelection("customer")}
                                />
                            }

                            <SelectionBox
                                Icon={UserOutlined}
                                title={"Als Gast fortfahren"}
                                description={"Ohne Benutzerkonto fortfahren"}
                                onClick={() => setSelection("guest")}
                            />

                            <br/>
                            <Button size={"large"} block disabled={saving} type={"default"} htmlType={"button"} onClick={handleBack}>Zurück</Button>
                        </Space>
                    </Col>

                :
                    selection === "customer" ?

                        (register ?
                            ( registerSuccess ?
                                    <>
                                        <Col span={24}>
                                            <h2>Konto erstellt</h2>
                                        </Col>
                                        <Col span={24}>
                                            <Row gutter={15}>
                                                <Col span={24}>
                                                    <p>Bitte E-Mail Adresse bestätigen um die Anmeldung abzuschließen. Hierzu einfach auf den Link in der Registrierungsmail klicken.</p>
                                                    <div style={{textAlign: 'center', paddingTop: '15px'}}>
                                                        <MailOutlined style={{fontSize: '5rem'}}/>
                                                        <br/>
                                                        <br/>
                                                        <span style={{fontSize: '1.3rem'}}>Bitte E-Mail Adresse bestätigen</span>
                                                    </div>
                                                    <div>
                                                        <br/>
                                                        <br/>
                                                        <Button
                                                            size={"large"}
                                                            block
                                                            onClick={() => {setRegister(false); setRegisterSuccess(false); }}
                                                            type={"primary"}
                                                            htmlType={"button"}
                                                        >Buchung fortfahren</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                            :
                                <>
                                    <Col span={24}>
                                        <h2>Konto erstellen</h2>
                                        {/*<p>Du möchtest dich nicht anmelden? <a href={"#"} onClick={() => setSelection("guest")}>Als Gast fortfahren</a></p>*/}
                                        <p>Neues Konto eröffnen. Bereits ein Konto? <a href={"#"} onClick={() => setRegister(false)}>Jetzt Anmelden</a></p>
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={15}>
                                            <Col span={24}>
                                                <RegisterForm onSuccess={() => { setRegisterSuccess(true); }} BackButton={() => <Button size={"large"} block disabled={saving} type={"default"} htmlType={"button"} onClick={() => setRegister(false)}>Zurück</Button>}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                            )
                            :
                            <>
                                <Col span={24}>
                                    <h2>Anmelden</h2>
                                    {/*<p>Du möchtest dich nicht anmelden? <a href={"#"} onClick={() => setSelection("guest")}>Als Gast fortfahren</a></p>*/}
                                    <p>Noch kein Benutzerkonto? <a href={"#"} onClick={() => setRegister(true)}>Jetzt Registrieren</a></p>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={15}>
                                        <Col span={24}>
                                            <LoginForm onSuccess={(jwtPayload) => onSuccess({customerId: jwtPayload.sub})} BackButton={() => <Button size={"large"} block disabled={saving} type={"default"} htmlType={"button"} onClick={handleBack}>Zurück</Button>}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </>)
                    :
                        <>
                            <Col span={24}>
                                <h2>Als Gast fortfahren</h2>
                                {/*<p>Du hast ein bereits Konto? <a href={"#"} onClick={() => setSelection("customer")}>Anmelden</a></p>*/}
                            </Col>
                            <Col span={24}>
                                <Form
                                    form={form}
                                    onFinish={handleSubmit}
                                    layout={"vertical"}
                                    size={"large"}
                                    initialValues={initialValues}
                                >
                                    <Row gutter={15}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"Anrede"}
                                                name={["guest", "title"]}
                                            >
                                                <Select>
                                                    <Select.Option value={"mr"}>Herr</Select.Option>
                                                    <Select.Option value={"mrs"}>Frau</Select.Option>
                                                    <Select.Option value={"company"}>Firma</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Form.Item shouldUpdate noStyle>
                                            {() => form.getFieldsValue(['guest'])?.guest?.title === "company" ?
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                        label={"Firma"}
                                                        name={["guest", "company"]}
                                                        required={true}
                                                        rules={[{required: true}]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            : null }
                                        </Form.Item>

                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"Vorname"}
                                                name={["guest", "firstName"]}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"Nachname"}
                                                name={["guest", "lastName"]}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"Straße, Hausnummer"}
                                                name={["guest", "address"]}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"PLZ"}
                                                name={["guest", "zipCode"]}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"Ort"}
                                                name={["guest", "city"]}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"E-Mail Adresse"}
                                                name={["guest", "mail"]}
                                                rules={[ {type: 'email', required: true}]}
                                                required={true}
                                            >
                                                <Input type={"email"} />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"Telefon"}
                                                name={["guest", "phone"]}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>


                                        <Col span={24}>
                                            <br/>
                                            <br/>
                                        </Col>


                                        <Col span={12}>
                                            <Button size={"large"} block disabled={saving} type={"default"} htmlType={"button"} onClick={handleBack}>Zurück</Button>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate
                                            >
                                                {() =>
                                                    <Button size={"large"} block disabled={saving || (form.getFieldsValue(['guest'])?.guest?.phone || "").length === 0} type={"primary"}
                                                            htmlType={"submit"} loading={saving}>Nächster Schritt</Button>
                                                }
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </Form>
                            </Col>
                        </>
                }
            </Row>
        </>
    );

}

export default BookContact;
