import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Row,
    Form, Space,
} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import BookItem from "./bookItem";
import BookForm from "./bookForm";
import api from "../../middleware/api";
import moment from "moment";
import PriceTable from "./PriceTable";
import {Route, Routes, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useApp} from "../app/AppProvider";


const Book = ({onSuccess, initialValues}) => {

    const [editBookingIndex, setEditBookingIndex] = useState(0);
    const [products, setProducts] = useState([]);
    const [values, setValues] = useState(initialValues);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const {mandatorInfo} = useApp();


    const loadProducts = async () => {

        const response = await api.get(`product/public`);

        if(response.data) {
            await setProducts(response.data);
        }
    };

    useEffect(() => {
        loadProducts();
    },[]);

    const saveBooking = (booking) => {
        const bookings = values?.bookings || [];
        bookings[editBookingIndex] = booking;

        setValues({...values, bookings});
        navigate('/cart');
    };

    const removeBooking = (bookingIndex) => {
        const bookings = (values?.bookings || []);
        bookings.splice(bookingIndex, 1);
        setValues({...values, bookings});
        if(bookings.length === 0) {
            navigate('/');
        }
    };

    const editBooking = (bookingIndex) => {
        setEditBookingIndex(bookingIndex);
        navigate('/');
    };

    const getInitialValues = () => {
        const actBooking = values?.bookings?.[editBookingIndex] || {};
        if(editBookingIndex === 0 && Object.keys(actBooking).length === 0) {
            const defaultProduct = searchParams.get('productId');
            if(defaultProduct) {
                actBooking.productId = defaultProduct;
            }
        }
        return {...actBooking, date: actBooking.date ? moment(actBooking.date, 'YYYY-MM-DD').toDate() : undefined, extraSession: values?.extraSession};
    };

    const getBlockedBookings = () => {
        let blocked = [];

        (values?.bookings || []).forEach((actBooking, bookingIndex) => {
            if(bookingIndex !== editBookingIndex) {
                blocked.push({...actBooking, date: actBooking.date ? moment(actBooking.date, 'YYYY-MM-DD').toDate() : undefined});
            }
        });

        return blocked;
    };



    const getPriceList = () => {

        let prices = {priceList: [], summary: 0};

        (values.bookings || []).forEach(booking => {
            prices.priceList = [...prices.priceList, ...booking.price.priceList];
            prices.summary += parseFloat(booking.price.summary);
        });

        prices.summary = parseFloat(prices.summary).toFixed(2);
        return prices;
    };

    const addBooking = () => {
        setEditBookingIndex((values?.bookings || []).length);
        navigate('/');
    };

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                window.scrollTo(0, 0);
                /*if((values?.bookings || []).length) {
                    navigate('/cart', {replace: true});
                }*/
                break;
            case '/cart':
                window.scrollTo(0, 0);
                if(!(values?.bookings || []).length) {
                    navigate('/', {replace: true});
                }
                break;
        }
    }, [location.pathname]);


    const Cart = () => {

        const [saving, setSaving] = useState(false);

        const handleSubmit = async () => {

            try {
                setSaving(true);

                const queryParams = initialValues?.extraSession ? `?extraSession=${initialValues?.extraSession}` : '';

                const response = await api.put(`booking/public/reserve${queryParams}`, values.bookings);

                if (response.data) {
                    const prices = getPriceList();
                    onSuccess({bookings: values.bookings, prices, extraSession: response.data});
                }
            } catch(e) {
                console.error(e);
            } finally {
                setSaving(false);
            }
        };

        return (
            <>
                <Col span={24}>
                    <Space direction={"vertical"} style={{width: '100%'}}>
                        {(values.bookings || []).map((booking, bIndex) =>

                            <BookItem
                                booking={{
                                    ...booking,
                                    product: products.find(p => p.id === booking.productId),
                                }}
                                onRemove={() => removeBooking(bIndex)}
                                onEdit={() => editBooking(bIndex)}
                            />
                        )}
                    </Space>
                </Col>
                <Col span={24}>
                    <br/>
                </Col>
                <Col span={24}>
                    <Button block size={"large"} icon={<PlusCircleOutlined />} onClick={addBooking}>Weitere Reservierung hinzufügen</Button>
                </Col>
                <Col span={24}>
                    <br/>
                </Col>
                <Col span={24}>
                    <h2>Preis</h2>
                </Col>

                <Col span={24}>
                    <PriceTable price={getPriceList()} />
                    <br/>
                </Col>
                <Col span={24}>
                    <Form.Item
                        noStyle
                        shouldUpdate
                    >
                        {() =>
                            <Button size={"large"} block type={"primary"} htmlType={"button"}
                                    loading={saving} onClick={handleSubmit}>Nächster Schritt</Button>
                        }
                    </Form.Item>
                </Col>
            </>
        );
    };

    return (
            <Row gutter={15}>

                <Col span={24}>
                    <h1 className={"login-H2"}>
                        Buchung {mandatorInfo.name}
                    </h1>
                    <p>Jetzt einfach Online gewünschten Termin vereinbaren! Daten ausfüllen um verfügbare Zeiten zu sehen.

                        </p>
                </Col>

                <Routes>
                    <Route path="/" element={<BookForm
                        initialValues={getInitialValues()}
                        blockedBookings={getBlockedBookings()}
                        onSuccess={saveBooking}
                        onBack={() => navigate('/cart')}
                        showBack={(values?.bookings || []).length > 0}
                    />}/>
                    <Route path="/cart" element={<Cart />}/>
                </Routes>

            </Row>
    );

}

export default Book;
