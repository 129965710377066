import React, {useEffect, useState} from 'react';
import styled  from 'styled-components/macro'
import moment from "moment";
import {Button, Col, Row, Modal} from "antd";
import api from "../middleware/api";
import {QuestionCircleOutlined} from '@ant-design/icons';

const Wrapper = styled.div`
          border: 1px solid #9e9e9e;
          border-radius: 5px;
          padding: 10px 25px;
          width: 100%;
          
          .productTitle {
            font-size: 1.2rem;
            display: block;
          }
          
          .actionIcon {
            vertical-align: bottom;
            height: 100%;
            font-size: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            
            &:hover {
                color: var(--global-red-color);
            }
          }
          
          .stornoBtn {
            vertical-align: bottom;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          
          .stornoTxt {
            color: var(--global-red-color);
          }
        `;

const MyBooking = ({booking, outdated, onCancel}) => {


    const [actionLoading, setActionLoading] = useState(false);

    const cancelBooking = async () => {
        try {
            setActionLoading(true);
            const response = await api.post(`booking/personal/${booking.id}/cancel`);
            onCancel && onCancel();
        } catch(e) {
            let error = "";
            switch(e.response.status) {
                case 410:
                    error = "Die Stornierung ist zu kurzfristig. Bitte für die Stornierung telefonisch an uns wenden.";
                    break;
                default:
                    error = "Die Stornierung konnte nicht durchgeführt werden. Bitte für die Stornierung telefonisch an uns wenden.";
                    break;
            }

            Modal.error({
                title: 'Fehler',
                content: (
                    <div>
                        <p>{error}</p>
                    </div>
                ),
            });
        } finally {
            setActionLoading(false);
        }
    };

    const askCancelBooking = () => {
        Modal.confirm({
            title: 'Reservierung Stornieren?',
            icon: <QuestionCircleOutlined />,
            content: 'Diese Reservierung wirklich stornieren?',
            okType: 'danger',
            okText: 'Stornieren',
            cancelText: 'Abbrechen',
            onOk: cancelBooking
        });
    };

    return (
        <Wrapper>
            <Row>
                <Col span={20}>
                    <small><b>{moment(booking.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</b> | {booking.persons} Teilnehmer</small>
                    <span className={'productTitle'}>{booking.product.name}</span>
                    {booking.bookingSlots.map((slot) => <><span>{moment(slot, 'YYYY-MM-DD HH:mm').format('HH:mm')} Uhr</span><br/></>)}
                </Col>
                <Col span={4} className={'stornoBtn'}>
                    {/*<StopOutlined className={'actionIcon'} />*/}
                    {!outdated && booking.status !== "canceled" ? <Button

                        danger
                        type="outlined"
                        htmlType={"button"}
                        onClick={askCancelBooking}
                        loading={actionLoading}
                    >Stornieren</Button> : null}
                    {booking.status === "canceled" && <h1 className={"stornoTxt"}>Storniert</h1>}
                </Col>
            </Row>
        </Wrapper>
    )
}

export default MyBooking;
