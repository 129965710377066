import React, {useEffect, useState} from 'react';
import {
    Alert, Button,
    Col, Empty, Form, message,
    Row, Space, Spin,
} from "antd";
import api from "../../middleware/api";
import MyBooking from "../../components/MyBooking";
import {UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const BookingList = ({pageSize, outdated, Placeholder, setActionError}) => {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [dataLoading, setDataLoading] = useState(false);
    const [error, setError] = useState("");


    const fetchData = async (page = 0) => {
        try {
            setError("");
            setDataLoading(true);

            const searchParams = new URLSearchParams({
                page,
                pageSize,
                outdated,
            });

            const response = await api.get(`booking/personal?${searchParams.toString()}`);
            if(response?.data?.data)
            {
                setPage(page);
                /*const data = {...response.data,
                    birthdate: response.data.birthdate ? moment(response.data.birthdate) : undefined,
                };*/
                setCount(response.data.count);
                if(page > 0) {
                    setData([...data, ...response.data.data]);
                } else {
                    setData(response.data.data);
                }

            }
        } catch(e) {
            setError("Es trat ein Fehler während dem Laden der Reservierungen auf. Bitte später erneut versuchen.");
        } finally {
            setDataLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            {error && <Alert type={"error"} message={error} banner />}
            <Spin spinning={dataLoading}>
                <Space direction="vertical" style={{width: '100%'}}>
                    {data.map(booking => <MyBooking booking={booking} outdated={outdated} onCancel={() => fetchData()} /> )}

                    {data.length < count ?
                        <div style={{textAlign: 'center', padding: '15px 0px'}}>
                            <Button htmlType={"button"} loading={dataLoading} onClick={() => fetchData(page + 1)}>ältere anzeigen</Button>
                        </div>
                    : null}

                    {Placeholder && data.length === 0 && !dataLoading ?
                        <Placeholder />
                    : null
                    }
                </Space>
            </Spin>
        </>
    );

}

export default BookingList;
