import React, {useEffect, useState} from 'react';
import {Col, Row, Space} from "antd";
import BookingSlot from "./BookingSlot";
import moment from "moment";

const BookingSlotSelector = ({ value, onChange, slots, multiSelect, persons }) => {

    const [selection, setSelection] = useState(value || []);

    useEffect(() => {
        setSelection(value || []);

        // maxbe?
        //onChange && onChange(value);
    }, [value]);


    const Status = {
        0: "Verfügbar",
        1: "Verfügbar",
        2: "Ausgebucht",
    };

    const StatusColor = {
        0: "green",
        1: "orange",
        2: "red",
    };

    const toggleSelection = (slot) => {
        let newSelection = selection;

        // slot already selected?
        if (selection.includes(slot.startDate)) {
            // deselect
            newSelection = selection.filter(sel => sel !== slot.startDate);
        } else {
            // else select slot...
            // is multiselect?
            if(multiSelect) {
                // then add slot to selection
                newSelection = [...selection, slot.startDate];
            } else {
                // else set slot as only selected one
                newSelection = [slot.startDate];
            }
        }

        setSelection(newSelection);
        onChange && onChange(newSelection);

    };


    return (
        <Row gutter={[8, 16]}>

            {slots.map((slot) => {
                const selected = selection.includes(slot.startDate);

                let tagText = "";
                if(slot.status !== undefined) {
                    tagText = Status?.[slot.status] || "";
                } else {
                    tagText = `${selected ? slot.used + persons : slot.used}/${slot.max}`;
                }

                return (
                    <Col xs={12} sm={12} key={slot.startDate}>
                        <BookingSlot
                            key={slot.startDate}
                            title={`${moment(slot.startDate, 'YYYY-MM-DD HH:mm').format("HH:mm")} - ${moment(slot.endDate, 'YYYY-MM-DD HH:mm').format("HH:mm")}`}
                            tag={tagText}
                            tagColor={slot.status !== undefined ? StatusColor?.[slot.status] : undefined}
                            selected={selected}
                            onChange={() => toggleSelection(slot)}
                        />
                    </Col>
                );
            })}


            {/*slots.map((slot) => <Col xs={12} sm={12}> <ProductDetail
                key={slot.startTime}
                title={`${moment(slot.startDate, 'YYYY-MM-DD HH:mm').format("HH:mm")} - ${moment(slot.endDate, 'YYYY-MM-DD HH:mm').format("HH:mm")}`}
                tag={slot.status !== undefined ? Status?.[slot.status] || "" : `${slot.used}/${slot.max}`}
                tagColor={slot.status !== undefined ? StatusColor?.[slot.status] : undefined}
                selected={selection.includes(slot.startDate)}
                onChange={() => toggleSelection(slot)} /></Col>)
            */}
        </Row>
    )
}

export default BookingSlotSelector;
