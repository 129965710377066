import React, {useEffect} from 'react';
import {Navigate, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useAuth} from "./AuthProvider";
import {Button, Row, Col, Form, Input, Modal, message} from "antd";
import "./auth.css";
import LoginForm from "../../components/LoginForm";
import RegisterForm from "../../components/RegisterForm";

const Register = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let {signout, JWT, authLoading} = useAuth();

    let from = location.state?.from?.pathname || "/bookings";
    const [searchParams] = useSearchParams();

    /*useEffect(() => {
        if (JWT) {
            navigate(from);
        }
    }, [JWT, searchParams]);*/

    return (
        <Row>
            <Col span={24}>
                <div className={"login-content"}>
                    <h1 className={"login-H2"}>
                        Konto erstellen
                    </h1>
                    <p>Neues Konto eröffnen. Bereits ein Konto? <a href={"#"} onClick={() => navigate("/login")}>Jetzt Anmelden</a></p>
                    <RegisterForm onSuccess={() => navigate('/register/success')} />
                </div>
            </Col>
        </Row>
    );
}

export default Register;
