import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    Col, Divider, Form, Input, message,
    Row, Select, Spin,
} from "antd";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import api from "../../middleware/api";
import moment from "moment";

const Settings = () => {

    const [saving, setSaving] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [dataLoading, setDataLoading] = useState(false);
    const [error, setError] = useState(false);
    const [form] = Form.useForm();




    const handleSubmit = async (values) => {
        try {
            setSaving(true);
            values = {
                ...values,
                birthdate: values.birthdate ? values.birthdate.local().format("YYYY-MM-DD") : undefined,
            };
            const response = await api.patch(`customer/profile`, values);
            message.success("Gespeichert");

            const data = {...response.data,
                birthdate: response.data.birthdate ? moment(response.data.birthdate) : undefined,
            };
            setProfileData(data);
            form.resetFields();
        } catch(e) {
            window.scrollTo(0,0);
            switch(e.response.status) {
                case 409:
                    setError("Diese E-Mail Adresse ist bereits registriert.");
                    break;
                default:
                    setError("Es ist ein unbekannter Fehler aufgetreten. Bitte später erneut versuchen.");
                    break;
            }

            console.error(e);
        } finally {
            setSaving(false);
        }
    };

    const fetchData = async () => {
        try {
            setDataLoading(true);
            const response = await api.get(`customer/profile`);
            if(response?.data)
            {
                const data = {...response.data,
                    birthdate: response.data.birthdate ? moment(response.data.birthdate) : undefined,
                };
                setProfileData(data);
            }
        } catch(e) {
            message.error("Laden fehlgeschlagen");
        } finally {
            setDataLoading(false);
        }
    };

    useEffect(() => {
        fetchData().then(() => form.resetFields());
    }, []);

    return (
        <Row>
            <Col span={24}>
                    <Row gutter={15}>

                        <Col span={24}>
                            <h1 className={"login-H2"}>
                                Profil
                            </h1>
                            <p>Persönlichen Daten verwalten.</p>
                        </Col>

                        <Col span={24}>
                            {error && <Alert type={"error"} message={error} banner />}
                        </Col>

                        <Col span={24}>
                            <Spin spinning={dataLoading}>
                                <Form
                                    form={form}
                                    onFinish={handleSubmit}
                                    layout={"vertical"}
                                    size={"large"}
                                    initialValues={profileData}
                                >
                                    <Row gutter={15}>

                                        <Col span={24}>
                                            <Divider orientation="left">Kontaktdaten</Divider>
                                        </Col>

                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"Anrede"}
                                                name={"title"}
                                            >
                                                <Select>
                                                    <Select.Option value={"mr"}>Herr</Select.Option>
                                                    <Select.Option value={"mrs"}>Frau</Select.Option>
                                                    <Select.Option value={"company"}>Firma</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Form.Item shouldUpdate noStyle>
                                            {() => form.getFieldsValue(['title'])?.title === "company" ?
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                        label={"Firma"}
                                                        name={"company"}
                                                        required={true}
                                                        rules={[{required: true}]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                : null }
                                        </Form.Item>

                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"Vorname"}
                                                name={"firstName"}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"Nachname"}
                                                name={"lastName"}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"Straße, Hausnummer"}
                                                name={"address"}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"PLZ"}
                                                name={"zipCode"}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"Ort"}
                                                name={"city"}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={"Telefon"}
                                                name={"phone"}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Divider orientation="left">Zugangsdaten</Divider>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label={"E-Mail Adresse"}
                                                name={'mail'}
                                            >
                                                <Input
                                                    disabled
                                                    prefix={<MailOutlined style={{ marginRight: '10px' }} />}
                                                    placeholder="E-Mail Adresse"
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label={"Passwort ändern"}
                                                name={'password'}
                                            >
                                                <Input.Password
                                                    prefix={<LockOutlined style={{ marginRight: '10px' }} />}
                                                    type="password"
                                                    placeholder="Passwort"
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label="Passwort ändern wiederholen"
                                                name="confirm"
                                                dependencies={['password']}
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!getFieldValue('password') || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('Ihre Passwörter stimmen nicht überein!'));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password
                                                    prefix={<LockOutlined style={{ marginRight: '10px' }} />}
                                                    type="password"
                                                    placeholder="Passwort"
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <br/>
                                        </Col>

                                        <Col span={24}>
                                            <Button size={"large"} block disabled={saving} type={"primary"} htmlType={"submit"} loading={saving} >Speichern</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Spin>
                        </Col>
                    </Row>

            </Col>
        </Row>
    );

}

export default Settings;
