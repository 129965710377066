import React from 'react';
import { Menu, Grid } from 'antd';
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../auth/AuthProvider";

const { useBreakpoint } = Grid;

const RightMenu = ({onSelect}) => {
    const { md } = useBreakpoint();

    const {JWT} = useAuth();

    const navigate = useNavigate();
    const location = useLocation();


    return (
        <Menu
            mode={md ? "horizontal" : "inline"}
            selectedKeys={[location.pathname]}
            onSelect={(route) => {
                navigate(route.key);
                onSelect(route.key);
            }}
        >
            <Menu.Item key={JWT ? "/login?logout=true" : "/login"}>
                <a>{JWT ? "Abmelden" : "Anmelden"}</a>
            </Menu.Item>

            {JWT ?
                <Menu.Item key="/profile/settings">
                    <a>Konto</a>
                </Menu.Item>
            : null }

            {JWT ?
                <Menu.Item key="/profile">
                    <a>Reservierungen</a>
                </Menu.Item>
            : null }

          <Menu.Item key="/">
            <a>Jetzt reservieren</a>
          </Menu.Item>
        </Menu>
    );
}

export default RightMenu;
