import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "antd";
import {CheckCircleOutlined, LoadingOutlined, CloseCircleOutlined} from "@ant-design/icons";
import {useNavigate, useSearchParams} from "react-router-dom";
import api from "../../middleware/api";

const ConfirmMail = () => {

    const navigate = useNavigate();
    const [result, setResult] = useState({loading: true, success: false});
    const [searchParams] = useSearchParams();

    const confirmMail = async () => {
        try {

            const requestSearchParams = new URLSearchParams({
                token: searchParams.get('token'),
            });


            await api.post(`auth/public/confirm?${requestSearchParams.toString()}`);

            setResult({loading: false, success: true});
        } catch (e) {
            setResult({loading: false, success: false});
        }
    };

    useEffect(() => {
        confirmMail();
    }, []);


    return (
        <Row>
            <Col span={24}>
                <div className={"login-content"}>
                    <h1 className={"login-H2"}>
                        E-Mail Adresse bestätigen
                    </h1>
                    <p>Die E-Mail Adresse wird bestätigt...</p>
                    <div style={{textAlign: 'center', paddingTop: '15px'}}>
                        {result.loading ?
                            <LoadingOutlined spin style={{fontSize: '5rem'}}/>
                            : (
                                result.success ?
                                    <>
                                        <CheckCircleOutlined style={{color: 'var(--global-main-color)', fontSize: '5rem'}}/>
                                        <br/>
                                        <br/>
                                        <span style={{fontSize: '1.3rem'}}>Erfolgreich bestätigt</span>
                                    </>
                                    :
                                    <>
                                        <CloseCircleOutlined style={{color: 'var(--global-red-color)', fontSize: '5rem'}}/>
                                        <br/>
                                        <br/>
                                        <span style={{fontSize: '1.3rem'}}>Die E-Mail Adresse konnte nicht verifiziert werden</span>
                                    </>
                            )
                        }
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <br/>
                        <span style={{fontSize: '1.3rem'}}>Diese Seite kann jetzt geschlossen werden.</span>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default ConfirmMail;
