import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Row,
    Space,
} from "antd";
import styled from "styled-components/macro";
import {EditOutlined, DeleteOutlined} from "@ant-design/icons";
import moment from "moment";



const Wrapper = styled.div`
          border: 1px solid #9e9e9e;
          border-radius: 5px;
          padding: 10px 25px;
          width: 100%;
          
          .productTitle {
            font-size: 1.2rem;
            display: block;
          }
          
          .actionIcon {
            vertical-align: bottom;
            height: 100%;
            font-size: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            
            &:hover {
                color: var(--global-red-color);
            }
          }
          
          .stornoBtn {
            vertical-align: bottom;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          
          .stornoTxt {
            color: var(--global-red-color);
          }
        `;

const BookItem = ({booking, onRemove, onEdit}) => {

    return (
                    <Wrapper>
                        <Row justify="space-around" align="middle">
                            <Col span={14}>
                                <small><b>{moment(booking.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</b> | {booking.persons} Teilnehmer</small>
                                <span className={'productTitle'}>{booking?.product?.name}</span>
                                {booking.bookingSlots.map((slot) => <><span>{moment(slot, 'YYYY-MM-DD HH:mm').format('HH:mm')} Uhr</span><br/></>)}
                            </Col>
                            <Col span={10}>
                                <div style={{textAlign: 'right'}}>
                                    <Space  align={"end"}>
                                        <Button
                                            type="outlined"
                                            htmlType={"button"}
                                            onClick={onEdit}
                                            loading={false}
                                        ><EditOutlined /></Button>
                                        <Button
                                            danger
                                            type="outlined"
                                            htmlType={"button"}
                                            onClick={onRemove}
                                            loading={false}
                                        ><DeleteOutlined /></Button>
                                    </Space>
                                </div>
                            </Col>
                        </Row>

                    </Wrapper>

    );

}

export default BookItem;
