import React from 'react';
import {Route, Routes} from "react-router-dom";
import { Layout } from "antd";
import "./app.css";
import "../../styles/index.css";
import Navbar from "./Navbar";
import Booking from "../booking";
import Login from "../auth/login";
import Register from "../auth/register";
import ForgotPassword from "../auth/forgotPassword";
import ResetPassword from "../auth/resetPassword";
import ConfirmMail from "../auth/confirm";
import RegisterSuccess from "../auth/registerSuccess";
import Profile from "../profile";
import CancelBooking from "../booking/cancel";
import BookingSuccess from "../booking/success";
import {useApp} from "./AppProvider";
import { CookieBanner } from "@palmabit/react-cookie-law";
import {initGTAG} from "../../helpers/gtag";

const { Header, Content, Footer } = Layout;

const App = () => {

    const {mandatorInfo} = useApp();


    return <>
            <Layout className="layout">
                <Header style={{ position: 'fixed', zIndex: 999, width: '100%' }}>
                    <Navbar />
                </Header>
                <Content className={"app-main-content"} >
                    <div className={"app-main-container"}>
                        <Routes>
                            <Route path="/*" element={<Booking />}/>
                            <Route path="/login" element={<Login />}/>
                            <Route path="/password/forgot" element={<ForgotPassword />}/>
                            <Route path="/password/reset" element={<ResetPassword />}/>
                            <Route path="/register" element={<Register />}/>
                            <Route path="/register/success" element={<RegisterSuccess />}/>
                            <Route path="/register/confirm" element={<ConfirmMail />}/>
                            <Route path="/profile/*" element={<Profile />}/>
                            <Route path="/booking/cancel" element={<CancelBooking />}/>
                            <Route path="/booking/success" element={<BookingSuccess />}/>
                        </Routes>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center'}}>
                    {mandatorInfo?.company}, {mandatorInfo?.address}, {mandatorInfo?.zipCode} {mandatorInfo?.city}
                    <br/>
                    Telefon: <a style={{color: 'inherit', textDecoration: 'underline'}} href={`tel:${mandatorInfo?.phone}`}>{mandatorInfo?.phone}</a>, E-Mail: <a style={{color: 'inherit', textDecoration: 'underline'}} href={`mailto:${mandatorInfo?.mail}`}>{mandatorInfo?.mail}</a>
                    <br/>
                    <a target="_blank" style={{color: 'inherit', textDecoration: 'underline'}} href={`${mandatorInfo?.imprintUrl}`}>Impressum</a> | <a target="_blank" style={{color: 'inherit', textDecoration: 'underline'}} href={`${mandatorInfo?.privacyUrl}`}>Datenschutz</a>
                </Footer>
                <CookieBanner
                    message={<><p className={"react-cookie-law-header"}>Cookies & Privatsphäre</p><p>Diese Webseite verwendet Cookies und Google Analytics für die Analyse und Statistik. Wir nutzen Cookies zu unterschiedlichen Zwecken, unter anderem zur Analyse und für personalisierte Marketing-Mitteilungen.</p></>}
                    wholeDomain={true}
                    policyLink={mandatorInfo?.privacyUrl}
                    privacyPolicyLinkText={"Datenschutz"}
                    necessaryOptionText={"Notwendig"}
                    managePreferencesButtonText={"Verwalten"}
                    acceptButtonText={"Zustimmen"}
                    savePreferencesButtonText={"Auswahl übernehmen"}
                    showPreferencesOption={false}
                    showStatisticsOption={false}
                    onAccept={() => {}}
                    onAcceptPreferences={() => {}}
                    onAcceptStatistics={() => {}}
                    onAcceptMarketing={() => { initGTAG(); }}
                />
            </Layout>
    </>;
};

export default App;
