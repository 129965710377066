import React, {useEffect, useState} from 'react';
import styled  from 'styled-components/macro'
import {Tag, Tooltip} from "antd";
import {HomeOutlined, InfoCircleOutlined} from '@ant-design/icons';

const Wrapper = styled.div`
          border: 1px solid #9e9e9e;
          border-radius: 5px;
          cursor: pointer;
          padding: 10px 25px;
          transition: border 0.3s ease-out;
          width: 100%;
          height: 100%;
          
          a.title {
            font-size: 1.1rem;
            font-weight: bold;
            margin-bottom: 5px;
          }
          
          a {
            color: inherit;
          }
          
          a.location {
            display: block;
            margin: 5px 0px;
          }
          
          &:not(.disabled):hover {
            border-color: var(--global-main-color);
          }
          
          &.active {
            /*border-color: var(--global-main-color);
            box-shadow: 0px 0px 7px -1px var(--global-main-color);*/
            background-color: var(--global-main-color);
            color: #000;
          }
          
          &.disabled {
            cursor: not-allowed;
            position: relative;
            color: #5c606a;
            border-color: #5c606a;
          }
        `;

const ProductDetail = ({product, selected, disabled, onChange}) => {

    function strip(html){
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    //const Loc = () => <svg style={{width: '1em', height: '1em', fill: 'currentColor'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" enable-background="new 0 0 50 50"><path d="M25 42.5l-.8-.9C23.7 41.1 12 27.3 12 19c0-7.2 5.8-13 13-13s13 5.8 13 13c0 8.3-11.7 22.1-12.2 22.7l-.8.8zM25 8c-6.1 0-11 4.9-11 11 0 6.4 8.4 17.2 11 20.4 2.6-3.2 11-14 11-20.4 0-6.1-4.9-11-11-11z"/><path d="M25 24c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"/></svg>;

    /*
SIMPLE VERSION
*/
    return (
    <Wrapper onClick={() => !disabled && onChange(product)} className={`${selected ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
        <a className={"title"}>{product.name}&nbsp;&nbsp;<Tooltip title={strip(product.productDetails || "")}><InfoCircleOutlined style={{fontSize: '1rem'}} /></Tooltip></a>

        {product.location ? <a className={"location"}><HomeOutlined style={{marginRight: '5px'}} />{product.location}</a> : <a className={"location"}></a>}

        {product.difficulty ? <Tag color={"green"}>{product.difficulty}</Tag> : null}
        {/*product.category && <Tag color={"orange"}>{product.category}</Tag>*/}
        {(!window.ENV.PRODUCT_HIDE_PERSONS && product.minPersons) ? <Tag color={"cyan"}>{product.minPersons} - {product.maxPersons} Personen</Tag> : null}
        {(!window.ENV.PRODUCT_HIDE_AGE && product.minAge) ? <Tag color={"blue"}>{product.minAge} - {product.maxAge || "99"} Jahre</Tag> : null}

    </Wrapper>
    );


/*
    return (
        <Wrapper onClick={() => !disabled && onChange(product)} className={`${selected ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
            <p className={"title"}>{product.name}</p>
            <p>{strip(product.productDetails || "")}</p>
            {product.location && <p><HomeOutlined style={{marginRight: '5px'}} />{product.location}</p>}
            <Tag color={"cyan"}>{product.minPersons} - {product.maxPersons} Personen</Tag>
            <Tag color={"blue"}>{product.minAge} - {product.maxAge || "99"} Jahre</Tag>
            {product.difficulty && <Tag color={"green"}>{productDifficulty?.[product.difficulty]}</Tag>}
            {product.category && <Tag color={"orange"}>{product.category}</Tag>}
        </Wrapper>
    )
 */
}

export default ProductDetail;
