import React, {useState} from 'react';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {Button, Row, Col, Form, Input, Alert} from "antd";
import "./auth.css";
import api from "../../middleware/api";
import {LockOutlined} from "@ant-design/icons";

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [notify, setNotify] = useState({});
    const [sent, setSent] = useState(false);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();

    const resetPassword = async (values) => {
        try {
            setLoading(true);

            const requestSearchParams = new URLSearchParams({
                token: searchParams.get('token'),
            });


            await api.post(`auth/public/password?${requestSearchParams.toString()}`, {password: values.password});
            setSent(true);
            setNotify({type: "success", message: "Passwort wurde erfolgreich geändert."});
        } catch (e) {
            window.scrollTo(0,0);
            switch(e.response.status) {
                case 403:
                    setNotify({
                        type: "error",
                        message: "Der Link ist ungültig. Bitte die Passwort vergessen Funktion erneut benutzen." });
                    break;
                default:
                    setNotify({
                        type: "error",
                        message: "Es ist ein unbekannter Fehler aufgetreten. Bitte später erneut versuchen." });
                    break;
            }
        }
        finally {
            setLoading(false);
        }
    };



    return (
        <Row>
            <Col span={24}>
                <div className={"login-content"}>
                    <h1 className={"login-H2"}>
                        Passwort zurücksetzen
                    </h1>
                    <p>Hier kann ein neues Passwort vergeben werden.</p>
                    {notify && notify?.message && <Alert type={notify.type} message={notify.message} banner action={notify?.action} />}
                    {sent ?
                        <Row gutter={8}>
                            <Col span={24}>
                                <br/>
                                <Button size={"large"} block onClick={() => navigate("/login")} type={"primary"} htmlType={"button"}
                                        loading={loading}>Jetzt Anmelden</Button>
                            </Col>
                        </Row>
                        :
                        <Form
                            form={form}
                            onFinish={resetPassword}
                            layout={"vertical"}
                            size={"large"}
                        >

                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item
                                        label={"Passwort"}
                                        name={'password'}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Input.Password
                                            prefix={<LockOutlined style={{ marginRight: '10px' }} />}
                                            type="password"
                                            placeholder="Passwort"
                                            size="large"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Passwort wiederholen"
                                        name="confirm"
                                        required={true}
                                        dependencies={['password']}
                                        rules={[{ required: true },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Ihre Passwörter stimmen nicht überein!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={<LockOutlined style={{ marginRight: '10px' }} />}
                                            type="password"
                                            placeholder="Passwort"
                                            size="large"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <br/>
                                    <Button size={"large"} block disabled={loading} type={"primary"} htmlType={"submit"}
                                            loading={loading}>Passwort speichern</Button>
                                </Col>
                            </Row>
                        </Form>
                    }
                </div>
            </Col>
        </Row>
    );
}

export default ResetPassword;
